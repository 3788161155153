import { useLocation, useOutlet } from "react-router-dom";
import { ReactNode } from "react";

interface PersistentLayoutCachedPage {
  path: string;
  exact?: boolean;
  component: ReactNode;
}

interface PersistentLayoutProps {
  cachedPages: PersistentLayoutCachedPage[];
  children?: ReactNode;
}

const matchPath = (
  currentPath: string,
  targetPath: string,
  exact?: boolean
): boolean => {
  if (exact) return currentPath === targetPath;
  return currentPath.startsWith(targetPath);
};

const PersistentLayout: React.FC<PersistentLayoutProps> = ({
  cachedPages,
  children,
}) => {
  const location = useLocation();
  const outlet = useOutlet();
  const currentPath = location.pathname;

  return (
    <>
      {cachedPages.map(({ path, component, exact }) => {
        const isActive = matchPath(currentPath, path, exact);
        return (
          <div
            key={path}
            className="cached-page"
            style={{
              display: "block",
              position: "absolute",
              width: "100%",
              height: "100%",
              visibility: isActive ? "visible" : "hidden",
            }}
          >
            {component}
          </div>
        );
      })}
      {children || outlet}
    </>
  );
};

export default PersistentLayout;
