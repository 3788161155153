import React from "react";
import { useRoutes } from "react-router-dom";
import { ConfigProvider, App as AntdApp } from "antd";
import { configResponsive } from "ahooks";
import routes from "@router/index";
import { defaultTheme } from "@styles/themeConfig";

configResponsive({
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1440,
});

const App: React.FC = () => {
  const element = useRoutes(routes);

  return (
    <ConfigProvider theme={defaultTheme} wave={{ disabled: true }}>
      <AntdApp className="app">{element}</AntdApp>
    </ConfigProvider>
  );
};

export default App;
