import { RouteObject } from "react-router-dom";
import Suspenselazy from "@components/Suspenselazy";
import PersistentLayout from "@/Layout/PersistentLayout";

const routes: RouteObject[] = [
  {
    element: (
      <PersistentLayout
        cachedPages={[
          {
            path: "/",
            exact: true,
            component: Suspenselazy(
              () => import(/* webpackChunkName: "Home" */ "@pages/Home")
            ),
          },
        ]}
      />
    ),
    children: [
      {
        path: "/",
        element: null,
      },
      {
        element: Suspenselazy(
          () =>
            import(
              /* webpackChunkName: "SecondaryLayout" */ "@/Layout/SecondaryLayout"
            )
        ),
        children: [
          {
            path: "/terms",
            element: Suspenselazy(
              () => import(/* webpackChunkName: "Terms" */ "@pages/Terms")
            ),
          },
          {
            path: "/privacy",
            element: Suspenselazy(
              () => import(/* webpackChunkName: "Privacy" */ "@pages/Privacy")
            ),
          },
          {
            path: "/careers",
            element: Suspenselazy(
              () => import(/* webpackChunkName: "Career" */ "@pages/Career")
            ),
          },
          {
            path: "/contact",
            element: Suspenselazy(
              () => import(/* webpackChunkName: "Touch" */ "@pages/Touch")
            ),
          },
          {
            path: "/demo",
            element: Suspenselazy(
              () => import(/* webpackChunkName: "Demo" */ "@pages/Demo")
            ),
          },
        ],
      },
      {
        path: "*",
        element: Suspenselazy(
          () => import(/* webpackChunkName: "NotFound" */ "@pages/NotFound")
        ),
      },
    ],
  },
];

export default routes;
